<template lang="pug">
page-wrapper
  template(slot="content")
    .d-flex.flex-column.justify-content-center.h-100
      om-heading.mt-5(h1) {{ $t('codeInsertV2.success.congratulation') }}
      om-heading(h1) {{ $t('codeInsertV2.success.connected') }}
      om-heading.mt-5(h5 v-if="domain") {{ domain.domain }}
  template(slot="image")
    img.m-auto(
      :alt="`${platform} logo`"
      :src="require(`@/assets/admin/svg/insertcode/platform_${illustration}.svg`)"
    )
</template>

<script>
  import { PageWrapper } from '@/components/CodeInsert';
  import store from '@/store';

  export default {
    components: {
      PageWrapper,
    },
    data: () => ({
      domain: null,
    }),
    computed: {
      platform() {
        return this.domain?.platform || '';
      },
      illustration() {
        return ['custom', 'other', ''].includes(this.platform) ? 'html' : this.platform;
      },
    },
    created() {
      const { domain } = this.$route.query;
      this.domain = store.state.account.settings.domains.find(
        ({ domain: savedDomain }) => savedDomain === domain,
      );
      if (!this.domain) {
        const name = this.$route.name.replace('success', 'domain');
        this.$router.push({ name });
      }
    },
  };
</script>
